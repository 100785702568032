<template>
  <v-container fluid>
<!--    <CustomerEditForm/>-->
    <PipelinesList/>
  </v-container>

</template>

<script>
import PipelinesList from '@/components/ongoing/PipelinesList';
// import CustomerEditForm from '@/components/ongoing/CustomerAddForm';

export default {
  components: { PipelinesList },
  data() {
    return {
    };
  },
};
</script>

<style scoped>

</style>
