<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Pipelines
            <div class="flex-grow-1"></div>
            <div class="flex-grow-1"></div>
            <v-btn color="primary" @click="onGetPipelinesInfo">Pipelines Inforamtion</v-btn>
          </v-card-title>
          <v-data-table
            :headers="pipelineKeys"
            :items="pipeItems"
            item-key="id"
            fixed-header
            :sort-by="['createdAt']"
            :sort-desc="['true']"
          >
          </v-data-table>
        </v-card>
        <v-card>
          <v-card-title>
            Deltas
            <div class="flex-grow-1"></div>
          </v-card-title>
          <v-data-table
            :headers="deltaKeys"
            :items="deltaItems"
            item-key="id"
            fixed-header
            :sort-by="['startDate']"
            :sort-desc="['true']"
          >
          </v-data-table>
        </v-card>

        <v-card>
          <v-card-title>
            Jobs
            <div class="flex-grow-1"></div>
<!--            <v-btn color="primary" @click="onGetJobs">Jobs</v-btn>-->
            <v-btn @click="runPipelineOnJobs">Create Delta</v-btn>
          </v-card-title>
          <v-data-table
            v-model="selected"
            :headers="jobKeys"
            :items="jobItems"
            item-key="id"
            fixed-header
            :sort-by="['startDate']"
            :sort-desc="['true']"
            :show-select= true
            v-on:input="limiter"
          >
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-row align="center" justify="center"/>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import {
  PIPELINES_INIT, DELTAS_INIT, JOBS_INIT, PIPELINES_REQUEST, PIPELINES_RUNNER_REQUEST, DELTAS_REQUEST, JOBS_REQUEST,
} from '@/store/actions/pipelines';

const { mapActions, mapGetters } = createNamespacedHelpers('pipelines');
export default {
  components: {
  },
  data() {
    return {
      selected: [],
      pipelineKeys: [
        {
          text: 'Pipeline Id',
          value: 'id',
        },
        {
          text: 'Start Date',
          value: 'startDate',
        },
        {
          text: 'End Date',
          value: 'endDate',
        },
        {
          text: 'Creation Status',
          value: 'creationStatus',
        },
        {
          text: 'Index Status',
          value: 'indexingStatus',
        },
        {
          text: 'Update Status',
          value: 'updateStatus',
        },
      ],
      deltaKeys: [
        {
          text: 'Delta Id',
          value: 'id',
        },
        {
          text: 'Source Job Id',
          value: 'sourceJobId',
        },
        {
          text: 'Target Job Id',
          value: 'targetJobId',
        },
        {
          text: 'startDate',
          value: 'startDate',
        },
        {
          text: 'End Date',
          value: 'endDate',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Processed',
          value: 'processed',
        },
        // {
        //   text: 'Failed',
        //   value: 'failed',
        // },
        // {
        //   text: 'Reason',
        //   value: 'reason',
        // },
        {
          text: 'Update Status',
          value: 'updateStatus',
        },
      ],
      jobKeys: [
        {
          text: 'Job Id',
          value: 'id',
        },
        {
          text: 'Version Name',
          value: 'versionName',
        },
        {
          text: 'Version Id',
          value: 'versionId',
        },
        {
          text: 'Start Date',
          value: 'startDate',
        },
        {
          text: 'End Date',
          value: 'endDate',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Processed',
          value: 'processed',
        },
        {
          text: '#Failed',
          value: 'failed',
        },
        // {
        //   text: 'Reason',
        //   value: 'reason',
        // },
      ],
    };
  },
  computed: {
    ...mapGetters(
      {
        pipeItems: 'pipelineGetResult',
        deltaItems: 'deltaGetResult',
        jobItems: 'jobsGetResult',
      },
    ),
  },
  // created() {
  //   this.customerList();
  // },
  methods: {
    ...mapActions({
      getPipelines: PIPELINES_REQUEST,
      getDeltas: DELTAS_REQUEST,
      getJobs: JOBS_REQUEST,
      pipelinesInit: PIPELINES_INIT,
      deltasInit: DELTAS_INIT,
      jobsInit: JOBS_INIT,
      runPipeline: PIPELINES_RUNNER_REQUEST,
    }),
    async onGetPipelinesInfo() {
      await this.pipelinesInit();
      await this.getPipelines();
      await this.getDeltas();
      await this.getJobs();
    },
    limiter(e) {
      if (e.length > 2) {
        e.pop();
      }
    },
    runPipelineOnJobs() {
      if (this.selected.length === 2) {
        this.runPipeline(this.selected);
      }
    },
  },
  created() {
    this.onGetPipelinesInfo();
  },
};
</script>
